import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getElementWidth } from "./ScreenManager";
import { PhoneAndroidRounded } from "@mui/icons-material";
import { useSendMobileNumberMutation } from "../services/user";
import LoadingDialog from "./LoadingDialog";

const PhoneNumDialog = ({ userToken, closeFn }) => {
  //#region HOOKS
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    error: false,
    disable: true,
  });

  const [stateIndex, setStateIndex] = useState(0);

  const [sendMobileNumber, { isLoading: loadingSendMobileNumberAPI, isSuccess: sendMobileNumberAPISuccess, data: sendMobileNumberAPIData, error: sendMobileNumberAPIError }] =
    useSendMobileNumberMutation();

  //#endregion

  let stateContent = null;

  if (stateIndex === 0) {
    const onPhoneNumChanged = (event) => {
      let enteredNumber = event.target.value;

      if (enteredNumber.length === 0) {
        setPhoneNumber({
          number: "",
          error: false,
          disable: true,
        });
        return;
      }

      if (enteredNumber.length > 10) {
        return;
      }

      if (enteredNumber.length < 10) {
        setPhoneNumber({
          number: enteredNumber,
          error: false,
          disable: true,
        });
      } else {
        setPhoneNumber({
          number: enteredNumber,
          error: false,
          disable: false,
        });
      }
    };

    stateContent = (
      <Dialog fullWidth={true} open={true}>
        <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
          <Typography
            style={{
              fontSize: getElementWidth(32),
              textAlign: "left",
              fontWeight: "100",
            }}
          >
            Please Enter Your Mobile Number To Continue
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: getElementWidth(10) }}>
          <TextField
            style={{
              width: "100%",
              borderRadius: "0",
              height: getElementWidth(100),
              fontSize: getElementWidth(30),
              marginBottom: "40px",
              padding: "0px",
            }}
            type="number"
            //classes={classesNew}
            error={phoneNumber.error}
            id="outlined-error-helper-text"
            //label="Error"
            placeholder="07XXXXXXXX"
            variant="outlined"
            value={phoneNumber.number}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidRounded />
                </InputAdornment>
              ),
            }}
            onChange={onPhoneNumChanged}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendMobileNumber({ userToken: userToken, phoneNum: phoneNumber.number });
                setStateIndex(1);
              }}
              style={{
                fontSize: getElementWidth(17),
                textTransform: "none",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              disableElevation
              disabled={phoneNumber.disable}
            >
              Submit
            </Button>
          </div>
          <Typography
            style={{
              fontSize: getElementWidth(17),
              textAlign: "center",
              fontWeight: "normal",
              lineHeight: "normal",
              whiteSpace: "pre-line",
            }}
          >
            {}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  } else if (stateIndex === 1) {
    if (loadingSendMobileNumberAPI) {
      stateContent = <LoadingDialog />;
    } else if (sendMobileNumberAPISuccess) {
      setTimeout(() => {
        closeFn();
      }, 100);
    } else if (sendMobileNumberAPIError) {
      setTimeout(() => {
        closeFn();
      }, 100);
    }
  }

  return <>{stateContent}</>;
};

export default PhoneNumDialog;
