import { useScrollTrigger } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { homeActions } from "../../store";
import { ImiPathsUrls } from "../../utils/ImiUtilData";
import LoginDialog from "../Game/LoginDialog";
import SubscribeDialog from "../Game/SubscribeDialog";
import ImiBottomNav from "./ImiBottomNav";
import ImiTopAppBar from "./ImiTopAppBar";
import PhoneNumDialog from "../../utils/PhoneNumDialog";

const ImiAppBars = (props) => {
  //#region HOOKS & APIS

  const navigate = useNavigate();
  const location = useLocation();
  const trigger = useScrollTrigger();
  const userToken = useSelector((state) => state.home.userToken);
  const isNavBarsHidden = useSelector((state) => state.home.isNavBarsHidden);
  const dispatch = useDispatch();

  const eventListener = (param1, event) => {
    if (typeof event.data === "string") {
      //CHECK FOR JSON OBJECT
      try {
        let commandObj = JSON.parse(event.data);
        console.log(commandObj);

        if (commandObj.command === "HIDE_NAV") {
          if (!isNavBarsHidden) {
            dispatch(homeActions.setNavBarStatus(true));
          }
        } else if (commandObj.command === "EXIT") {
          navigate(param1.state?.from?.pathname || ImiPathsUrls.home, {
            replace: true,
          });
        } else if (commandObj.command === "SUBSCRIBE") {
          if (!openSubscribeDialog) {
            setOpenSubscribeDialog(true);
          }
        } else if (commandObj.command === "LOGIN") {
          if (!openLogInDialog) {
            setOpenLogInDialog(true);
          }
        }
      } catch (e) {
        console.log("JSON Parse Error" + e);
      }
    }
  };

  const eventListenerFnRef = useRef(eventListener);

  useEffect(() => {
    //console.log("Here");

    window.removeEventListener("message", eventListenerFnRef.current);

    eventListenerFnRef.current = (event) => eventListener(location, event);

    window.addEventListener("message", eventListenerFnRef.current, false);
  }, [location]);

  useEffect(() => {
    if (location.pathname !== ImiPathsUrls.game) {
      dispatch(homeActions.setNavBarStatus(false));
    }
  }, [location]);

  //#endregion

  //#region LOGIN DIALOG
  const [openLogInDialog, setOpenLogInDialog] = useState(false);

  const onLogInDialogActionBtnClick = (status) => {
    // if (logInBool) {
    //   document.getElementById("gameIframe").src += "&ingamelogin=true";
    // } else {
    //   document.getElementById("gameIframe").src += "";
    // }

    setOpenLogInDialog(false);

    if (status) {
      setOpenPhoneNumDialog(true);
    } else {
      document.getElementById("gameIframe").src += "";
    }
  };

  let logInDialog = null;

  if (openLogInDialog) {
    logInDialog = <LoginDialog onClose={onLogInDialogActionBtnClick} />;
  }

  //#endregion

  //#region PHONE NUMBER DIALOG
  const [openPhoneNumDialog, setOpenPhoneNumDialog] = useState(false);

  const phoneNumDialogCloseFn = () => {
    document.getElementById("gameIframe").src += "";

    setOpenPhoneNumDialog(false);
  };

  let phoneNumberDialog = null;
  if (openPhoneNumDialog) {
    phoneNumberDialog = <PhoneNumDialog userToken={userToken} closeFn={phoneNumDialogCloseFn} />;
  }

  //#endregion

  //#region SUBSCRIBE DIALOG
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);

  const onSubscribeDialogActionBtnClick = () => {
    document.getElementById("gameIframe").src += "";
    setOpenSubscribeDialog(false);
  };

  let subscribeDialog;

  if (openSubscribeDialog) {
    subscribeDialog = <SubscribeDialog open={true} onClose={onSubscribeDialogActionBtnClick} />;
  } else {
    subscribeDialog = null;
  }

  //#endregion

  const isTopHide = isNavBarsHidden ? true : trigger;

  return (
    <Fragment>
      <ImiTopAppBar trigger={!isTopHide} />
      {props.children}
      <ImiBottomNav trigger={!isNavBarsHidden} />

      {logInDialog}
      {phoneNumberDialog}
      {subscribeDialog}
    </Fragment>
  );
};

export default ImiAppBars;
