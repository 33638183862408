import { Facebook, Google } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { useGetUserProfileMutation, useGetUserTokenFBMutation, useGetUserTokenGoogleMutation } from "../../services/user";
import { homeActions } from "../../store";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import LoadingDialog from "../../utils/LoadingDialog";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { makeStyles } from "@mui/styles";
import { DATA_KEY, googleWebClientID, ImiPathsUrls } from "../../utils/ImiUtilData";
import { useNavigate } from "react-router-dom";

const LoginDialog = ({ onClose }) => {
  //#region STYLES
  const useStyles = makeStyles(() => ({}));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginToken = useRef(null);

  //USER TOKEN FB
  const [getUserTokenFB, { data: fbTokenData, error: fbTokenDataError, isLoading: isLoadingFbTokenData }] = useGetUserTokenFBMutation();

  //USER TOKEN FB
  const [getUserTokenGoogle, { data: googleTokenData, error: googleTokenDataError, isLoading: isLoadingGoogleTokenData }] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [getUserProfile, { data: userProfileData, error: userProfileDataError, isLoading: isLoadingUserProfileData }] = useGetUserProfileMutation();

  //#endregion

  //#region UTIL

  const onLoginDialogActionBtnClick = (e, status) => {
    onClose(status);
  };

  const responseFacebook = (response) => {
    //console.log(response);

    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const responseGoogle = (response) => {
    //console.log(response);

    if (response.tokenId) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(response.tokenId);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const legalContracts_BtnClick = () => {
    onLoginDialogActionBtnClick();
    navigate(ImiPathsUrls.profile, {
      replace: true,
    });
  };

  //#endregion

  //#region USER TOKEN APIS STATES

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (isLoadingFbTokenData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenDataError) {
      //console.log(fbTokenDataError);

      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(5);
            onClose(false);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    } else if (fbTokenData) {
      //console.log(fbTokenData);

      loginToken.current = fbTokenData.access_token;
      //dispatch(homeActions.setUserToken(fbTokenData.access_token));
      setTokenAPIStateIndex(3);
      getUserProfile(fbTokenData.access_token);
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (isLoadingGoogleTokenData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenDataError) {
      //console.log(googleTokenDataError.error);

      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(5);
            onClose(false);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    } else if (googleTokenData) {
      //console.log(googleTokenData);

      //SAVE TO LOCAL STORAGE
      //dispatch(homeActions.setUserToken(googleTokenData.access_token));
      loginToken.current = googleTokenData.access_token;
      setTokenAPIStateIndex(3);
      getUserProfile(googleTokenData.access_token);
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (isLoadingUserProfileData) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileDataError) {
      console.log(userProfileDataError);
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(5);
            onClose(false);
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong!",
          }}
        />
      );
    } else if (userProfileData) {
      dispatch(homeActions.setUserToken(loginToken.current));
      dispatch(
        homeActions.setUserData({
          name: userProfileData.name,
          image: userProfileData.image_url,
        })
      );

      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({
          token: loginToken.current,
          name: userProfileData.name,
          image: userProfileData.image_url,
        })
      );

      setTokenAPIStateIndex(0);
      //console.log("SUCCESS");
      onClose(true);
    }
  }
  //FEEDBACK DIALOG
  else if (tokenAPIStateIndex === 4) {
    tokenAPIStateContent = (
      <FeedbackDialog
        id="Error Dialog"
        open={true}
        onClose={() => {
          setTokenAPIStateIndex(5);
          onClose(false);
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong!",
        }}
      />
    );
  } else if (tokenAPIStateIndex === 5) {
    tokenAPIStateContent = null;
  }
  //LOGIN DIALOG
  else {
    tokenAPIStateContent = (
      <Fragment>
        <Dialog open={true} fullWidth={true}>
          <DialogTitle>
            <Typography
              style={{
                fontSize: getElementHeight(35),
                textAlign: "left",
                fontWeight: "100",
              }}
            >
              Please login to continue!
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <FacebookLogin
              appId="421341401639640"
              autoLoad={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(75, 111, 167,255)",
                    display: "flex",
                    flexDirection: "row",
                    width: getElementHeight(480),
                    height: getElementHeight(80),
                    //display : 'flex',
                    marginTop: getElementHeight(20),
                    //marginBottom : getElementWidth(20),
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                  startIcon={<Facebook sx={{ color: "white" }} />}
                >
                  <Typography color="white" fontSize={getElementHeight(28)} fontWeight="bold" textTransform="none">{`Facebook`}</Typography>
                </Button>
              )}
              isMobile={true}
              disableMobileRedirect={true}
            />

            <GoogleLogin
              clientId={googleWebClientID}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(214, 72, 34,255)",
                    display: "flex",
                    flexDirection: "row",
                    width: getElementHeight(480),
                    height: getElementHeight(80),
                    //display : 'flex',
                    marginTop: getElementHeight(20),
                    //marginBottom : getElementWidth(20),
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                  startIcon={<Google sx={{ color: "white" }} />}
                >
                  <Typography color="white" fontSize={getElementHeight(28)} fontWeight="bold" textTransform="none">{`Google`}</Typography>
                </Button>
              )}
              buttonText="Login"
              autoLoad={false}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              //cookiePolicy={'single_host_origin'}
            />

            <div style={{ height: getElementHeight(40) }}></div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: getElementHeight(20),
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                By Logging in, you agree to our&nbsp;
                <span style={{ color: "rgba(199,18,57,255)" }} onClick={legalContracts_BtnClick}>
                  Terms and Conditions{" "}
                </span>
                and acknowledge that you have read the
                <span style={{ color: "rgba(199,18,57,255)" }} onClick={legalContracts_BtnClick}>
                  {" "}
                  Privacy Policy.
                </span>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onLoginDialogActionBtnClick} color="primary">
              <Typography style={{ fontSize: getElementHeight(30), textAlign: "left" }}>Cancel</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
  //#endregion

  return <Fragment>{tokenAPIStateContent}</Fragment>;
};

export default LoginDialog;
