import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, MobileStepper, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCatalogListQuery } from "../../services/catalog";
import { homeActions } from "../../store";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { CatalogId, DATA_KEY } from "../../utils/ImiUtilData";
import ImiImageList from "./ImiImageList";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@emotion/react";
import { getElementHeight } from "../../utils/ScreenManager";
import Banner1 from "../../assets/Banners/Banner1.png";
import Banner2 from "../../assets/Banners/Banner2.png";
import Banner3 from "../../assets/Banners/Banner3.png";
import Banner4 from "../../assets/Banners/Banner4.png";
import Banner5 from "../../assets/Banners/Banner5.png";
import { useLayoutEffect } from "react";
import ImiImageList2 from "./ImiImageList2";

const ImiHome = () => {
  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //localStorage.removeItem("token");

    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      if (_userData) {
        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
          })
        );
      }
    }
  }, []);

  //#endregion

  //#region GAME LIST CONTENT

  const gameListContent = (
    <Box
      sx={{
        height: getElementHeight(340),
        paddingLeft: getElementHeight(3),
        paddingRight: getElementHeight(3),
      }}
    >
      <ImiImageList title="Play And Enjoy" type="GAME" key={CatalogId.Game_ListId} isListLoading={false} id={CatalogId.Game_ListId} />
    </Box>
  );

  //#endregion

  //#region MORE FUN LIST CONTENT

  const moreFunContent = (
    <Box
      sx={{
        paddingLeft: getElementHeight(4.57),
      }}
    >
      <ImiImageList2 />
    </Box>
  );

  //#endregion

  //#region  BANNER CAROUSEL

  const images = [
    {
      label: "Banner 1",
      imgPath: Banner1,
    },
    {
      label: "Banner 2",
      imgPath: Banner2,
    },
    {
      label: "Banner 3",
      imgPath: Banner3,
    },
    {
      label: "Banner 4",
      imgPath: Banner4,
    },
    {
      label: "Banner 5",
      imgPath: Banner5,
    },
  ];

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const bannerCarouselContent = (
    <Box
      sx={{
        height: getElementHeight(600),
        flexGrow: 1,
        paddingLeft: getElementHeight(4.57),
        paddingRight: getElementHeight(4.57),
      }}
    >
      <Box
        style={{
          height: getElementHeight(120),
          display: "flex",
          alignItems: "flex-end",
          paddingBottom: getElementHeight(13),
          fontSize: "0px",
        }}
      ></Box>

      <AutoPlaySwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: getElementHeight(400),
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Box
        style={{
          height: getElementHeight(50),
        }}
      ></Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        style={{
          justifyContent: "center",
        }}
      />
    </Box>
  );
  //#endregion

  //#region VIDEO LIST CONTENT
  const videoListContent = (
    <Box
      sx={{
        paddingLeft: getElementHeight(4.57),
      }}
    >
      <ImiImageList title="How To Play" type="VIDEO" key={CatalogId.Video_ListId} isListLoading={false} id={CatalogId.Video_ListId} />
    </Box>
  );

  //#endregion

  return (
    <Fragment>
      {gameListContent}
      {/* {moreFunContent} */}
      {/* {bannerCarouselContent} */}
      {/* {videoListContent} */}
      <DummyBottomSpace />
    </Fragment>
  );
};

export default ImiHome;
